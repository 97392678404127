.AdministrationMenu {
  .MenuList {
    .Link {
      height: 100%;
      width: 100%;
      text-decoration: none;
      line-height: 3.6;

      .LinkSelected {
        color: #263238;
      }
    }
    &:hover {
      background: #0d47a1;
    }

    &.Selected {
      background: #fafafa;
      color: #263238;
    }
  }

  .MenuPaper {
    background: #1565c0;
  }
}