.Ticket {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 76px);
  margin: 0;
  padding: 20px;
  border-radius: 10px;
  background: #FBFBFB;
  box-shadow: 2px 2px 10px 0 rgb(0 0 0 / 50%);
  font-family: 'courier-prime', monospace;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 5px;
    height: 6px;
    width: calc(100% - 20px);
  }

  &:before {
    top: -5px;
    background: radial-gradient(circle, transparent, transparent 50%, #FBFBFB 50%, #FBFBFB 100% ) -7px -8px / 16px 16px repeat-x,
  }

  &:after {
    bottom: -5px;
    background: radial-gradient(circle, transparent, transparent 50%, #FBFBFB 50%, #FBFBFB 100% ) -7px -2px / 16px 16px repeat-x,
  }

  .TicketContent {
    box-sizing: border-box;
    min-height: 100%;
    max-height: 0;
    width: 100%;
    border: 4px solid #D8D8D8;
    margin-bottom: 16px;
    overflow: auto;
  }


}
