.AnimalsView {
  height: calc(100vh - 80px);
  overflow: auto;
  padding: 8px;

  display: flex;
  flex-direction: column;

  .LotteryContainer {
    height: 80px;
  }

  .RaffleContainer {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }

  .RaffleItem {
    padding-left: 0 !important;
  }

}