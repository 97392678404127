.FruitCard {
  position: relative;

  .isSelected {
    position: absolute;
    right: -8px;
    top: -12px;
    z-index: 1;
  }
}
